.reset-password-box {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  width: 25rem;
  margin-top: 5px;
  overflow-y: auto;
}

.reset-password-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
}

.reset-password-field {
  margin-bottom: 1.5rem;
}

.reset-password-label {
  display: block;
  color: #4a5568;
  font-size: 1rem;
}

.reset-password-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  font-size: 1rem;
}

.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-toggle-button {
  position: absolute;
  right: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.password-text {
  font-size: 0.85rem;
  color: black;
  line-height: 1.2;
  margin: 0.5rem 0;
  max-width: 300px;
  word-wrap: break-word;
}

.error-text {
  color: red;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

.reset-password-button {
  width: 100%;
  color: white;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: #085CBE;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.2rem;
  border: none;
}

.reset-password-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
