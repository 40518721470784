@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply overflow-hidden h-screen bg-center bg-cover bg-no-repeat bg-white;
    font-family: 'Poppins', sans-serif;
  }
  body {
    @apply overflow-auto h-full bg-white;
    font-family: 'Poppins', sans-serif;
  }

  .bg-color {
    background-color: #ffffff;
  }
  .no-margin {
    margin: 0;
  }
  .bold {
    font-weight: bold;
  }
  .rtl {
  direction: rtl;
  text-align: right;
}

}